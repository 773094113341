import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PostContainer, PostHeader } from "../components/styled/post.js"
import { Link } from "gatsby"
import { Container } from "../components/styled/container"

const NotFoundPage = () => (
  <Layout>
    <Container main>
      <SEO title="404: Not found" />
      <PostHeader>
        <h2 style={{ marginBottom: 16 }}>NOT FOUND</h2>
      </PostHeader>
      <PostContainer>
        <p style={{ textAlign: "center" }}>
          You just hit a route that doesn&#39;t exist... the sadness.{" "}
          <Link to="/">Go home</Link>
        </p>
      </PostContainer>
    </Container>
  </Layout>
)

export default NotFoundPage
