import styled from "styled-components"

export const PostContainer = styled.div`
  margin: 0 auto;
  max-width: 616px;
  margin-bottom: 24px;
`

export const PostTags = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-top: 8px;
`

export const PostTag = styled.div`
  background-color: ${props => props.theme.colors.primary};
  padding: 4px 8px;
  margin-top: 8px;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: ${props => (props.right ? "8px" : 0)};
`

export const Tag = styled.small`
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.theme.colors.white};
`

export const PostHeader = styled.div`
  text-align: center;
  padding-top: 24px;
  padding-bottom: 24px;
`

export const PostImageWrapper = styled.div`
  margin: 0 auto;
  max-width: 780px;
  margin-bottom: 24px;
`
